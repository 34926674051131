import { Box, Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE } from "../App/AuthGuard";
import { useTranslation } from "react-i18next";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
                alt=""
                width={200}
                height={100}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">{t("common.pageNotFound")}</Typography>
              <Button
                variant="contained"
                onClick={() => navigate(DASHBOARD_ROUTE)}
                sx={{ mt: 2 }}
              >
                {t("common.backHome")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

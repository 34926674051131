import { fetchCategories as fetchCategoriesAction } from "../../state/categoriesSlice";

export const fetchCategories = (userId?: number, groupId?: number) => {
  const filters = {
    user: {
      $or: [
        {
          id: {
            $null: true,
          },
        },
        {
          id: {
            $eq: userId,
          },
        },
      ],
    },
    group: {
      id: {
        ...(groupId
          ? {
              $eq: groupId,
            }
          : {
              $null: true,
            }),
      },
    },
  };

  return fetchCategoriesAction({
    filters,
  });
};

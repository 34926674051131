import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import requestFactory from "../services/requestFactory";
import { CategoryType } from "../types/category";
import { MetaType, StrapiDataType, StrapiQuery } from "../types/request";
import { RootState } from "./store";

const initialState: StrapiDataType<CategoryType[]> = {
  data: [] as CategoryType[],
  meta: {} as MetaType,
};

const CATEGORIES_SLICE_NAME = "categories";

const categoriesSlice = createSlice({
  name: CATEGORIES_SLICE_NAME,
  initialState,
  reducers: {
    setCategories: (
      state,
      action: PayloadAction<StrapiDataType<CategoryType[]>>
    ) => {
      const { data, meta } = action.payload;
      state.data = data;
      state.meta = meta;
    },
  },
});

export const { setCategories } = categoriesSlice.actions;

// ==== SELECTORS ====
const selectCategoriesState = (state: RootState) => state.categories;

export const selectCategories = createSelector(
  selectCategoriesState,
  (categoriesState) => categoriesState.data
);

// ==== THUNKS ====
export const fetchCategories = createAsyncThunk(
  CATEGORIES_SLICE_NAME + "/fetch",
  async (params: StrapiQuery | undefined, thunkAPI) => {
    const response = await requestFactory.fetchCategories(params);

    if (response.status === 200) {
      await thunkAPI.dispatch(setCategories(response.data));
    }

    return response;
  }
);

export default categoriesSlice.reducer;

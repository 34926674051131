import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { store } from "../state/store";
import { StrapiQuery } from "../types/request";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: { "Content-Type": "application/json" },
});

class RequestService {
  axiosInstance: AxiosInstance;
  jwt?: string;

  constructor() {
    this.axiosInstance = axiosInstance;
  }

  setAuthorization() {
    const jwt = store.getState().auth.jwt;

    if (!jwt) {
      delete this.axiosInstance.defaults.headers["Authorization"];
    } else {
      this.axiosInstance.defaults.headers["Authorization"] = `Bearer ${jwt}`;
    }
  }

  processStrapiFilters(strapiQuery?: StrapiQuery) {
    if (!strapiQuery) {
      return "";
    }

    const query = qs.stringify(
      {
        filters: strapiQuery.filters,
      },
      {
        encodeValuesOnly: true,
        ...(strapiQuery.options || {}),
      }
    );

    console.log("query", query);

    return `?${query}`;
  }

  async doRequest(
    request: () => Promise<AxiosResponse>
  ): Promise<AxiosResponse> {
    this.setAuthorization();

    try {
      const response = await request();

      return response;
    } catch (error) {
      const typedError = error as AxiosError;

      return typedError.response!;
    }
  }

  async post(url: string, data: Record<string, any>) {
    const response = await this.doRequest(() =>
      this.axiosInstance.post(url, data)
    );

    return response;
  }

  async get(url: string, strapiQuery?: StrapiQuery) {
    const urlWithQuery = `${url}${this.processStrapiFilters(strapiQuery)}`;

    const response = await this.doRequest(() =>
      this.axiosInstance.get(urlWithQuery)
    );

    return response;
  }
}

const requestService = new RequestService();

export default requestService;

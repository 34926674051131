import { TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { ControlledFormInputProps } from "../../types/form";

export const ControlledTextInput: React.FC<ControlledFormInputProps> = ({
  name,
  control,
  label,
  componentProps,
  errors,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          {...(componentProps || {})}
          label={label}
          error={!!errors?.[name]}
        />
      )}
    />
  );
};

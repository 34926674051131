import { useEffect } from "react";
import { selectLoggedUserId } from "../../state/authSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Logout } from "../Logout";
import { fetchCategories } from "./actions";

export const Dashboard = () => {
  const dispatch = useAppDispatch();
  const loggedUserId = useAppSelector(selectLoggedUserId);

  useEffect(() => {
    dispatch(fetchCategories(loggedUserId));
  }, [dispatch, loggedUserId]);

  return (
    <div>
      Dashboard
      <Logout />
    </div>
  );
};

import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import requestFactory from "../services/requestFactory";
import { CredentialsType, UserType } from "../types/user";
import { RootState } from "./store";

const initialState: {
  authenticated: boolean;
  loggedUser?: UserType;
  jwt?: string;
} = {
  authenticated: false,
  jwt: undefined,
  loggedUser: undefined,
};

const AUTH_SLICE_NAME = "auth";

const authSlice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ user: UserType; jwt: string }>) => {
      const { user, jwt } = action.payload;

      state.loggedUser = user;
      state.authenticated = true;
      state.jwt = jwt;
    },
  },
});

export const { login } = authSlice.actions;

// ==== SELECTORS ====
const selectAuthState = (state: RootState) => state.auth;

export const selectIsUserAuthenticated = createSelector(
  selectAuthState,
  (authData) => authData.authenticated
);

export const selectLoggedUser = createSelector(
  selectAuthState,
  (authData) => authData.loggedUser
);

export const selectLoggedUserId = createSelector(
  selectLoggedUser,
  (loggedUser) => loggedUser?.id
);

export const selectJwt = createSelector(
  selectAuthState,
  (authData) => authData.jwt
);

// ==== THUNKS ====
export const loginUser = createAsyncThunk(
  AUTH_SLICE_NAME + "/login",
  async (data: CredentialsType, thunkAPI) => {
    const response = await requestFactory.login(data);

    if (response.status === 200) {
      await thunkAPI.dispatch(login(response.data));
    }

    return response;
  }
);

export default authSlice.reducer;

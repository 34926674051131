import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../components/hooks";
import { selectIsUserAuthenticated } from "../state/authSlice";

export const DASHBOARD_ROUTE = "/";
export const LOGIN_ROUTE = "/login";

export const AuthGuard: React.FC<
  React.PropsWithChildren & { requireAuth?: boolean }
> = ({ children, requireAuth = true }) => {
  const isUserAuthenticated = useAppSelector(selectIsUserAuthenticated);

  if (requireAuth && isUserAuthenticated) {
    // used for components that are visible only when user authenticated
    return <>{children}</>;
  }

  if (!requireAuth && !isUserAuthenticated) {
    // used for components that are visible only when user id not authenticated
    return <>{children}</>;
  }

  if (!requireAuth && isUserAuthenticated) {
    // cannot access non authenticated routes as authenticated user

    return <Navigate to={DASHBOARD_ROUTE} replace />;
  }

  // cannot access authenticated routes as non authenticated user
  return <Navigate to={LOGIN_ROUTE} replace />;
};

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ControlledTextInput } from "./form/ControlledTextInput";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormErrors } from "./form/FormErrors";
import { FormDivider } from "./form/FormDivider";
import { loginUser } from "../state/authSlice";
import { CredentialsType } from "../types/user";
import { useAppDispatch } from "./hooks";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE } from "../App/Routes";
import { AppFormType } from "../types/form";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("form.emailInvalid")
    .typeError("form.emailInvalid")
    .required("form.emailRequired"),
  password: Yup.string()
    .typeError("form.passwordRequired")
    .required("form.passwordRequired"),
});

export const Login = () => {
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<AppFormType<CredentialsType>>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data: CredentialsType) => {
    const response = await dispatch(loginUser(data));
    const responsePayload = response.payload as AxiosResponse;

    if (responsePayload.status === 200) {
      clearErrors(["formLevelError"]);

      navigate(DASHBOARD_ROUTE);
    } else if (responsePayload.status === 400) {
      setError("formLevelError", { message: "form.login.invalidCredentials" });
    } else {
      setError("formLevelError", { message: "form.login.unknownError" });
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
        margin: "0 auto",
        maxWidth: "500px",
      }}
    >
      <ControlledTextInput
        name="email"
        control={control}
        errors={errors}
        label={t("common.email")}
      />
      <FormDivider />
      <ControlledTextInput
        label={t("common.password")}
        name="password"
        control={control}
        errors={errors}
        componentProps={{
          type: "password",
        }}
      />
      <FormDivider />
      <FormErrors errors={errors} />
      <Button variant="outlined" onClick={handleSubmit(onSubmit)}>
        {t("common.login")}
      </Button>
    </Box>
  );
};

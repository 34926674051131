import { AxiosResponse } from "axios";
import { CategoryType } from "../types/category";
import { StrapiDataType, StrapiQuery } from "../types/request";
import { CredentialsType, UserType } from "../types/user";
import requestService from "./request";

class RequestFactory {
  async login(
    data: CredentialsType
  ): Promise<AxiosResponse<{ user: UserType; jwt: string }>> {
    const response = await requestService.post("/auth/local", {
      password: data.password,
      identifier: data.email,
    });

    return response;
  }
  async fetchCategories(
    params?: StrapiQuery
  ): Promise<AxiosResponse<StrapiDataType<CategoryType[]>>> {
    const response = await requestService.get("/categories", params);

    return response;
  }
}

const requestFactory = new RequestFactory();

export default requestFactory;

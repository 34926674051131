import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "./hooks";

const resetStateAction = { type: "RESET_STATE" };

export const Logout = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <Button onClick={() => dispatch(resetStateAction)}>
      {t("common.logout")}
    </Button>
  );
};

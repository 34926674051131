import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSliceReducer from "./authSlice";
import categoriesSliceReducer from "./categoriesSlice";

const persistConfig = {
  key: "root",
  storage,
};

const reducerSlices = combineReducers({
  auth: authSliceReducer,
  categories: categoriesSliceReducer,
});

const persistedReducer = persistReducer(persistConfig, reducerSlices);

export type RootState = ReturnType<typeof persistedReducer>;

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type === "RESET_STATE") {
    storage.removeItem("persist:root");

    state = {} as RootState;
  }

  return persistedReducer(state, action);
};

export const store = configureStore({
  // @ts-ignore TODO: check this
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  // @ts-ignore TODO: check this
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import { Routes, Route } from "react-router-dom";
import { Dashboard } from "../components/dashboard/Dashboard";
import { Login } from "../components/Login";
import { NotFoundPage } from "../components/NotFoundPage";
import { AuthGuard } from "./AuthGuard";

export const DASHBOARD_ROUTE = "/";
export const LOGIN_ROUTE = "/login";

const routesConfig = [
  {
    path: DASHBOARD_ROUTE,
    Element: Dashboard,
  },
  {
    path: LOGIN_ROUTE,
    Element: Login,
    requireAuth: false,
  },
];

export const AppRoutes = () => {
  return (
    <Routes>
      {routesConfig.map(({ path, Element, requireAuth }) => (
        <Route
          key={path}
          path={path}
          element={
            <AuthGuard requireAuth={requireAuth}>
              <Element />
            </AuthGuard>
          }
        />
      ))}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

import { Alert, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormErrorProp } from "../../types/form";
import { FormDivider } from "./FormDivider";

export const FormErrors: React.FC<FormErrorProp> = ({ errors }) => {
  const { t } = useTranslation();
  const errorKeys = Object.keys(errors);

  if (!errorKeys.length) {
    return null;
  }

  return (
    <>
      <Alert variant="outlined" severity="error">
        {errorKeys.map((errKey) => {
          const messageTranslationPath = errors[errKey]?.message?.toString();

          if (!messageTranslationPath) {
            return null;
          }

          return (
            <Typography key={messageTranslationPath} variant="body2">
              {t(messageTranslationPath)}
            </Typography>
          );
        })}
      </Alert>
      <FormDivider />
    </>
  );
};
